<template>
  <TableStyleWrapper2>
    <TableWrapper class="table-responsive">
      {{connectionsTableData}}
      <a-table
          :pagination="false"
          :dataSource="connectionsTableData"
          :columns="columns"
      />
    </TableWrapper>
  </TableStyleWrapper2>
</template>
<script>
import {TableStyleWrapper2, TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import VueTypes from "vue-types";

const ConnectionTable = defineComponent({
  name: 'ConnectionTable',
  components: {TableStyleWrapper2, TableWrapper},
  props: {
    connections: VueTypes.array.def([]),
    columns: VueTypes.array.def([]),
  },
  setup(prop) {
    const connectionsTableData = computed(() =>
        prop.connections.map((connection) => {
          const {ean} = connection;

          return {
            key: ean,
            ean: <span>{ean}</span>,
          };
        }),
    );

    return {connectionsTableData};
  },
});

export default ConnectionTable;
</script>

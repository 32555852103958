<template>
 <UserBioBox>
  <sdCards title="Contactgegevens">
   <a-row v-if="!order || !order.relation">
    <a-col :xs="24">
     <div class="user-info">
      <a-skeleton active/>
     </div>
    </a-col>
   </a-row>
   <a-row v-else>
    <a-col :md="12" :xs="24">
     <div class="user-info">
      <ul class="user-info__contact">
       <li>
        <template v-if="order.relation.type==='CONSUMER'">
         <sdFeatherIcons size="14" type="user"/>
         <span>{{
           order.relation.gender === 'MALE' ? 'Dhr.' : ''
          }}{{
           order.relation.gender === 'FEMALE' ? 'Mevr.' : ''
          }} {{
           order.relation.firstName
          }}{{
           order.relation.middleName && order.relation.middleName !== '' ? ` ${order.relation.middleName}` : ''
          }} {{ order.relation.lastName }}</span>
        </template>
        <template v-if="order.relation.type==='BUSINESS'">
         <sdFeatherIcons size="14" type="briefcase"/>
         <span>{{ order.relation.companyName }}<br/>{{
           order.relation.companyCocNumber
          }}</span>
        </template>
       </li>
       <li>
        <sdFeatherIcons size="14" type="home"/>
        <span>{{ order.relation.street }} {{
          order.relation.houseNumber
         }}{{
          order.relation.houseNumberAddition && order.relation.houseNumberAddition !== '' ? order.relation.houseNumberAddition : ''
         }}<br/>{{ order.relation.postalCode }} {{
          order.relation.city
         }} {{ order.relation.country === 'NL' ? 'Nederland' : '' }}</span>
       </li>
       <li>
        <sdFeatherIcons size="14" type="mail"/>
        <span>{{ order.relation.email }}</span>
       </li>
      </ul>
     </div>
    </a-col>
    <a-col :md="12" :xs="24">
     <div class="user-info">
      <ul class="user-info__contact">
       <li>
        <sdFeatherIcons size="14" type="phone"/>
        <span>{{ order.relation.phoneNumber }}</span></li>
       <li v-if="order.relation.bankAccountNumber !== null">
        <sdFeatherIcons size="14" type="credit-card"/>
        <span>{{ order.relation.bankAccountNumber }}<br/>{{ order.relation.debtorName }}</span>
       </li>
      </ul>
     </div>
    </a-col>
   </a-row>
  </sdCards>
 </UserBioBox>
</template>
<script>
import {UserBioBox} from './style';
import {defineComponent} from 'vue';
import VueTypes from "vue-types";
import moment from "moment";

const RelationSummaryCard = defineComponent({
 name: 'RelationSummaryCard',
 components: {UserBioBox},
 props: {
  order: VueTypes.object.def(null),
 },
 setup() {
  return {moment};
 },
});

export default RelationSummaryCard;
</script>

<template>
 <div v-if="isLoader" class="spin">
  <a-spin/>
 </div>
 <FlowCard v-else style="margin-bottom: 30px">
  <figure>
   <div v-if="data.type === 'SOLAR'" style="text-align: center">
    <img :src="require('../../../static/img/flows/solar.jpg')" alt=""
         style="max-height: 100px;max-width: 120px"/>
   </div>
   <div
    v-else
    class="pt-10"
    style="text-align: center;"
   >
    <i v-if="data.type === 'ENERGY'" class="fa fa-bolt" style="font-size: 60px;"></i>
    <i v-if="data.type === 'ENERGY'" class="fas fa-burn" style="font-size: 60px;padding-left:5px;"></i>
    <i v-if="data.type === 'SVM'" class="fa fa-file" style="font-size: 60px;"></i>
    <i v-if="data.type === 'LEAD'" class="fa fa-calendar-check-o" style="font-size: 60px;"></i>
    <i v-if="data.type === 'SOLARSIMPLE'" class="fa fa-solar-panel" style="font-size: 60px;"></i>
    <i v-if="data.type === 'SHOP' && data.imageFileURL === null" class="fa fa-store" style="font-size: 60px;"></i>
    <div v-if="data.type === 'SHOP' && data.imageFileURL !== null" style="height:80px;text-align: center">
     <img :src="data.imageFileURL" alt=""
          style="max-height: 100px;max-width: 120px"/>
    </div>
    <div v-if="data.type === 'HYDROMX'" style="height:80px;text-align: center">
     <img alt="" src="https://www.hydromx.com/wp-content/uploads/2021/09/hydromx_logo.png"
          style="max-width: 200px"/>
    </div>
   </div>
  </figure>
  <figcaption>
   <sdHeading as="h6" style="text-align: center;font-size: 24px; padding-bottom: 40px">
    {{ data.displayName }}
   </sdHeading>
   <div>
    <a-row :gutter="20">
     <a-col v-if="data.signatureTypes.SIGNATURE" :xs="getColSize('SIGNATURE')">
      <sdButton class="btn-cart" size="small" style="width:100% !important;" type="primary"
                @click="handleSelectFlow('SIGNATURE', data)">
       <sdFeatherIcons class="mr-2" size="14" type="file-text"/>
       Overeenkomst
      </sdButton>
     </a-col>
     <a-col v-if="data.signatureTypes.ESIGNATURE" :xs="getColSize('ESIGNATURE')">
      <sdButton class="btn-cart" size="small" style="width:100% !important;" type="primary"
                @click="handleSelectFlow('ESIGNATURE', data)">
       <sdFeatherIcons class="mr-2" size="14" type="mail"/>
       Offerte
      </sdButton>
     </a-col>
    </a-row>
   </div>
  </figcaption>
 </FlowCard>
</template>
<script>
import {FlowCard} from '../Style';
import PropTypes from 'vue-types';
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';

const FlowCards = defineComponent({
 name: 'FlowCards',
 components: {FlowCard},
 props: {
  data: PropTypes.object,
 },
 methods: {
  handleSelectFlow(flow, type) {
   this.$emit('set-flow', {config: type, flow: flow})
  },
  getColSize(type) {
   if (type === 'SIGNATURE') {
    if (this.data.signatureTypes.SIGNATURE && this.data.signatureTypes.ESIGNATURE) {
     return 12;
    }
    if (this.data.signatureTypes.SIGNATURE && !this.data.signatureTypes.ESIGNATURE) {
     return 24;
    }
   }
   if (type === 'ESIGNATURE') {
    if (this.data.signatureTypes.ESIGNATURE && this.data.signatureTypes.SIGNATURE) {
     return 12;
    }
    if (this.data.signatureTypes.ESIGNATURE && !this.data.signatureTypes.SIGNATURE) {
     return 24;
    }
   }
  }
 },
 setup() {
  const {dispatch, state} = useStore();
  const isLoader = computed(() => state.flows.loadingFlows);
  const {matched} = useRoute();

  return {
   dispatch,
   isLoader,
   matched,
  };
 },
});

export default FlowCards;
</script>

<template>
 <sdPageHeader title="Zonnepanelen"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #address>
          <sdCards title="Adres selecteren">
           <!--           <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">-->
           <!--            <line :x2="(Math.cos(-45 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(-45 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            <line :x2="(Math.cos(0 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(0 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            &lt;!&ndash; If you do not specify the stroke-->
           <!--                 color the line will not be visible &ndash;&gt;-->
           <!--           </svg>-->
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <div class="create-account-form">
              <a-form
               :model="addressForm"
               :rules="addressFormRules"
               layout="vertical"
               name="account"
               @finish="globalSearch"
              >
               <a-row :gutter="30">
                <a-col :sm="10" :xs="24">
                 <a-form-item label="Postcode" name="postalCode">
                  <a-input v-model:value="addressForm.postalCode" placeholder=""/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item label="Huisnummer" name="houseNumber">
                  <a-input v-model:value="addressForm.houseNumber"
                           onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder="" type="number"/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                              name="houseNumberAddition"
                 >
                  <a-select v-model:value="addressForm.houseNumberAddition" :allowClear="true"
                            class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                    :key="index"
                                    :value="item.value" name="energyAllowSingleFuelElectricity">
                    {{ item.key }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24">
                 <a-form-item label="&nbsp;">
                  <a-button :disabled="disabledGlobalSearchButton||globalSearchLoading||loadingHouseNumberAdditions"
                            :loading="globalSearchLoading||loadingHouseNumberAdditions" class="btn-signin w-100"
                            html-type="submit"
                            size="large"
                            type="primary">
                   Zoeken
                  </a-button>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </a-col>
           </a-row>
          </sdCards>
          <a-row v-if="globalSearchData && globalSearchData.address" :gutter="30">
           <a-col :md="14">
            <sdCards title="Klant selecteren">
             <a-row style="padding: 0 !important;">
              <a-col :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Nieuwe klanten</sdHeading>
                <a-row :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;">
                 <a-col :xl="16" :xs="24"
                        class="p-2 mb-2" style="vertical-align: middle;display: table-cell;align-self: center">
                  {{ globalSearchData.address.street }}
                  {{
                   globalSearchData.address.houseNumber
                  }} {{
                   globalSearchData.address.houseNumberAddition ? globalSearchData.address.houseNumberAddition : ''
                  }}<br/>
                  {{ globalSearchData.address.postalCode }} {{ globalSearchData.address.city }} <br/>Nederland
                 </a-col>
                 <a-col :xl="8" :xs="24" justify="end"
                        style="vertical-align: middle;align-self: center;text-align: end">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'CONSUMER')">
                   Doorgaan als particulier
                  </a-button>
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'BUSINESS')">
                   Doorgaan als zakelijk
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.relations.length > 0" justify="center">
              <a-col :sm="24" :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Bestaande klanten</sdHeading>
                <a-row v-for="relation in globalSearchData.relations" :key="relation.id" :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                 <a-col :xl="18" :xs="24" style="vertical-align: middle;display: table-cell;align-self: center;">
                                          <span><strong>{{ relation.fullName }}</strong>
                                                                                      <span class="status-badge ml-2">{{
                                                                                        relation.type && relation.type === 'BUSINESS' ? 'Zakelijk' : 'Particulier'
                                                                                       }}</span>
                                          </span><br/>
                  <span>{{ relation.street }} {{
                    relation.houseNumber
                   }} {{
                    relation.houseNumberAddition ? relation.houseNumberAddition : ''
                   }}<br/> {{ relation.postalCode }} {{ relation.city }}<br/> Nederland</span><br/>
                 </a-col>
                 <a-col :xl="6" :xs="24"
                        style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(relation,relation.type)"> Selecteren
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.coc.length > 0" ustify="center">
              <a-col :sm="24" :xs="24" class="mb-2">
               <sdHeading as="h6" class="mb-5" style="font-size:14px">Gevonden in<img
                :src="require(`@/static/img/kvk.svg`)"
                alt="" class="ml-5"/></sdHeading>
               <a-row v-for="coc in globalSearchData.coc" :key="coc.companyCocNumber" :gutter="30"
                      style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                <a-col :xl="18" :xs="24"
                       class="p-2" style="vertical-align: middle;display: table-cell;align-self: center">
                                          <span><strong>{{ coc.companyName }} - {{
                                            coc.companyCocNumber
                                           }}</strong></span><br/>
                 <span>{{ coc.street }} {{
                   coc.houseNumber
                  }} {{
                   coc.houseNumberAddition ? coc.houseNumberAddition : ''
                  }}<br/> {{ coc.postalCode }} {{ coc.city }}<br/>Nederland</span>
                </a-col>
                <a-col :xl="6" :xs="24" justify="end"
                       style="vertical-align: middle;align-self: center;text-align: end">
                 <a-button :disabled="loadingPreFillRelationOnSolarOrder"
                           :loading="loadingPreFillRelationOnSolarOrder"
                           class="mr-2 mb-2 mt-2 w-100"
                           size="large"
                           type="primary-outlined"
                           @click.prevent="handleSelectRelation(coc,'BUSINESS')"> Doorgaan als zakelijk
                 </a-button>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
           </a-col>
           <a-col :md="10">
            <sdCards style="text-align: center" title="Locatie">
             <img :src="globalSearchData.streetViewImageFileURL" style="width: 100%;height: auto;border-radius:5px">
            </sdCards>
           </a-col>
          </a-row>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <router-link to="/order/flow">
                 <sdButton class="btn-prev" to="" type="primary">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </router-link>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #roofSurface>
          <a-row :gutter="30">
           <a-col :md="14" :xs="24">
            <l-map v-if="!loadingFetchRoofData && roofData" ref="map" :center="mapCenter" :use-global-leaflet="true"
                   :zoom="19"
                   style="height:60vh;border-radius: 5px">
             <!--             <l-tile-layer-->
             <!--              :detedRetina="true"-->
             <!--              :max-zoom="21"-->
             <!--              :min-zoom="15"-->
             <!--              layer-type="base"-->
             <!--              name="Google satelliet"-->
             <!--              url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&size=400x500&scale=4"-->
             <!--             ></l-tile-layer>-->
             <l-tile-layer
              :detedRetina="true"
              :max-zoom="19"
              :min-zoom="15"
              layer-type="base"
              name="Terrein"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             ></l-tile-layer>
             <l-tile-layer
              :detedRetina="true"
              :max-zoom="20"
              :min-zoom="15"
              layer-type="base"
              name="Street view"
              url="https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_ortho25/EPSG:3857/{z}/{x}/{y}.jpeg"
             ></l-tile-layer>
             <l-control-layers/>
             <l-draw-toolbar position="topright"/>
             <l-control position="bottomright">
              <div
               style="width:220px;height:100px;border-radius: 5px;background: rgba(255,255,255, 0.8);padding:25px 20px 20px 20px">
               <div style="width:100%;float:left"><h6 style="font-size: 11px;font-weight: bold;padding-bottom:5px">
                Dakpotentieel</h6></div>
               <div style="width:100%;float:left">
                <span
                 style='background:#00c853;display: block;float: left;height: 15px;width: 30px;border-radius: 2px 0px 0px 2px'></span>
                <span style='background:#aeea00;display: block;float: left;height: 15px;width: 30px;'></span>
                <span style='background:#fdd835;display: block;float: left;height: 15px;width: 30px;'></span>
                <span style='background:#ffab00;display: block;float: left;height: 15px;width: 30px;'></span>
                <span style='background:#ff6d00;display: block;float: left;height: 15px;width: 30px;'></span>
                <span
                 style='background:#dd2c00;display: block;float: left;height: 15px;width: 30px;border-radius: 0px 2px 2px 0px'></span>
               </div>
               <div style="width:100%;float:left">
                <div style="width:50%;float:left;font-size: 10px;text-align: start;color:#000000">
                 uitstekend
                </div>
                <div style="width:50%;float:left;font-size: 10px;text-align: end;color:#000000;">
                 matig
                </div>
               </div>
              </div>
             </l-control>
             <l-feature-group ref="featureGroup" @ready="onFeatureGroupReady($event)"/>
             <l-geo-json v-if="roofData" :key="options.key" ref="map" :geojson="roofData" :options="options"/>
            </l-map>
           </a-col>
           <a-col :md="10" :xs="24" class="mt-2">
            <MixedCardWrap>
             <OverviewCard>
              <div class="d-flex align-items-center justify-content-between overview-head"
                   style="margin-bottom: 20px !important;">
               <sdHeading as="h4">Dakdelen</sdHeading>
              </div>
              <a-form ref="formRoofRef" :model="{surfaces:order.surfaces}" layout="vertical">
               <a-row gutter="20">
                <a-col v-if="order.surfaces.length < 1" :xs="24">
                 <OrderMapInfoCard style="margin-top:10px">
                  <sdCards headless>
                   <a-empty :image="simpleImage" description="Geen dakdeel geselecteerd"/>
                  </sdCards>
                 </OrderMapInfoCard>
                </a-col>
                <a-col v-for="(item,index) in order.surfaces" v-else :key="index + order.key" :xs="24">
                 <OrderMapInfoCard style="margin-top:10px">
                  <div class="overview-box">
                   <sdCards headless>
                    <div class="project-title">
                     <h1>
                      Dakdeel {{ index + 1 }} -
                      {{ getRoofPotentialInfo(item.properties.Roofpotential).potential }}
                     </h1>
                     <!--                  <sdFeatherIcons size="16" type="trash-2"/>-->
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                     <div class="order-summary-inner">

                      <ul class="summary-list" style="margin-top: 5px !important;">
                       <li style="padding-bottom: 0px; margin-bottom: 5px">
                     <span class="summary-list-title">Instralingsverlies <sdPopover action="hover"
                                                                                    content="Onder instralingsverlies wordt verstaan het verlies aan instraling door een minder gunstige helling en oriëntatie van het dakvlak ten opzichte van 34 graden op het Zuiden."
                                                                                    placement="top"><sdFeatherIcons
                      size="18"
                      style="vertical-align: middle"
                      type="info"/></sdPopover>:</span>
                        <span
                         class="summary-list-text">{{
                          getRoofPotentialInfo(item.properties.Roofpotential).irradiationLoss
                         }}</span>
                       </li>
                       <li style="padding-bottom: 0px; margin-bottom: 5px">
                        <span style="width:30%">Dakhelling :</span>
                        <div style="width:70%">
                         <a-row gutter="10">
                          <!--                          <a-col v-if="item.properties.ID.toString().startsWith('100.')" :xs="16">-->
                          <!--                           <a-slider :id="`surfaceInclinationSlider${item.key}`"-->
                          <!--                                     :default-value="0"-->
                          <!--                                     :marks="{0:{label: 'Plat'},89: {label: 'Hellend',}}"-->
                          <!--                                     :max="89"-->
                          <!--                                     :min="0"-->
                          <!--                                     :name="`surfaceInclinationSlider${item.key}`"-->
                          <!--                                     :step="1"-->
                          <!--                                     :tooltipVisible="false"-->
                          <!--                                     :value="item.properties.Inclination"-->
                          <!--                                     @change="adjustInclination($event,item)"/>-->
                          <!--                          </a-col>-->
                          <!--                          <a-col :xs="item.properties.ID.toString().startsWith('100.')? 8:24" style="text-align: end">-->
                          <a-col :xs="24" style="text-align: end">
                           {{ item.properties.Inclination }}°
                           <!--                           <canvas :id="`canvasSurfaceInclination${item.key}`" height="35"-->
                           <!--                                   style="border: 2px solid black;border-radius:5px"-->
                           <!--                                   width="35">-->
                           <!--                           </canvas>-->

                          </a-col>
                         </a-row>
                        </div>
                       </li>
                       <li style="padding-bottom: 0px; margin-bottom: 5px">
                        <span class="summary-list-title">Oriëntatie :</span>
                        <span class="summary-list-text">{{ item.properties.Orientation }}°</span>
                       </li>
                       <li style="padding-bottom: 0px; margin-bottom: 5px">
                        <span class="summary-list-title">Oppervlak :</span>
                        <span class="summary-list-text">{{ item.properties.Area }}</span>
                       </li>
                       <li style="padding-bottom: 0px; margin-bottom: 5px">
                        <span class="summary-list-title">Dakgoothoogte :</span>
                        <span class="summary-list-text">{{ item.properties.Gutter }}m</span>
                       </li>
                       <li style="margin-bottom: 0">
                        <span class="summary-list-title">Schaduw:</span>
                        <div class="summary-list-text" style="width:175px">
                         <a-form-item :name="['surfaces', index, 'ShadowLoss']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="">
                          <a-select v-model:value="item.properties.ShadowLoss" :allowClear="true"
                                    class="mapSelect"
                                    size="large">
                           <a-select-option v-for="(item,index) in solarShadowLossOptions" :key="index"
                                            :name="['surfaces', index, 'ShadowLoss']"
                                            :value="item.value">
                            {{ item.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </div>
                       </li>
                       <li>
                        <span class="summary-list-title">Daktype:</span>
                        <div class="summary-list-text" style="width:175px">
                         <a-form-item :name="['surfaces', index, 'RoofType']"
                                      :rules="{required: true,message: 'Dit veld is verplicht'}"
                                      label="">
                          <a-select v-model:value="item.properties.RoofType" :allowClear="true"
                                    class="mapSelect"
                                    size="large">
                           <a-select-option v-for="(i,index) in roofTypeOptions" :key="i"
                                            :name="['surfaces', index, 'RoofType']"
                                            :value="i.value">
                            {{ i.key }}
                           </a-select-option>
                          </a-select>
                         </a-form-item>
                        </div>
                       </li>
                      </ul>
                     </div>
                    </div>
                   </sdCards>
                  </div>
                 </OrderMapInfoCard>
                </a-col>
               </a-row>
              </a-form>
             </OverviewCard>
            </MixedCardWrap>

           </a-col>
           <ActionWrapper>
            <div class="step-action-wrap">
             <div class="step-action-inner">
              <a-row>
               <a-col :xs="24">
                <div class="steps-action">
                 <sdButton class="btn-prev" type="primary" @click="() => prev()">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                 <sdButton :disabled="order.surfaces.length < 1" class="btn-next"
                           type="primary"
                           @click.prevent="mapNext">
                  <span>Volgende</span>
                  <sdFeatherIcons size="16" type="arrow-right"/>
                 </sdButton>
                </div>
               </a-col>
              </a-row>
             </div>
            </div>
           </ActionWrapper>
          </a-row>
         </template>
         <template #product>
          <a-row :gutter="30">
           <a-col :md="6">
            <sdCards title="Uw jaarlijkse stroomverbruik">
             <a-form :model="order" layout="vertical" name="sDash_vertical-form" @finish="fetchSolarPackages">
              <a-form-item class="removeMinHeight"
                           label="" name="solarEnergyUsage">
               <div class="sDash-switch-list" style="min-height: auto !important;padding-left: 5px;">
                Mijn verbruik inschatten
                <a-switch v-model:checked="order.usageEstimator"/>
               </div>
              </a-form-item>
              <a-form-item v-if="order.usageEstimator"
                           :label="order.relation.type === 'CONSUMER'?'Hoeveel personen wonen er op dit adres':'Wat js uw bedrijfssituaties?'"
                           class="removeMinHeight">
               <a-row>
                <a-col
                 v-if="order.relation.type === 'CONSUMER'"
                 :xs="24"
                 style="text-align:center;border: 2px solid #d5d5d5;border-radius: 5px;padding-left:0px;padding-right:0px"
                >
                 <EstimateSolarButtonWrapper>
                  <button
                   :class="selectedUsageProfile === 1 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   @mouseenter="usageEstimatorHoverText = '1 persoon'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(1,'1 persoon')"
                  >
                   <i
                    class="fas fa-male"
                   />
                  </button>
                  <button
                   :class="selectedUsageProfile === 2 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = '2 personen'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(2,'2 personen')"
                  >
                   <i
                    class="fas fa-male"
                   /><i
                   class="fas fa-male"
                  />
                  </button>
                  <button
                   :class="selectedUsageProfile === 3 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   type="primary"
                   @mouseenter="usageEstimatorHoverText = '3 personen'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(3,'3 personen')"
                  >
                   <i
                    class="fas fa-male"
                   /><i
                   class="fas fa-male"
                  /><i
                   class="fas fa-male"
                  />
                  </button>
                  <button
                   :class="selectedUsageProfile === 4 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = '4 personen'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(4,'4 personen')"
                  >
                   <i
                    class="fas fa-male"
                   /><i
                   class="fas fa-male"
                  /><i
                   class="fas fa-male"
                  /><i
                   class="fas fa-male"
                  />
                  </button>
                  <button
                   :class="selectedUsageProfile === 5 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = '5 personen'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(5, '5 personen')"
                  >
                   <i
                    class="fas fa-male"
                   /><i
                   class="fas fa-male"
                  /><i
                   class="fas fa-male"
                  /><i
                   class="fas fa-male"
                  /><i
                   class="fas fa-male"
                  />
                  </button>
                 </EstimateSolarButtonWrapper>
                </a-col>
                <a-col
                 v-if="order.relation.type === 'BUSINESS'"
                 :xs="24"
                 style="text-align:center;border: 2px solid #d5d5d5;border-radius: 5px;padding-left:0px;padding-right:0px"
                >
                 <EstimateSolarButtonWrapper>
                  <button
                   :class="selectedUsageProfile === 6 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = 'ZZP'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(6, 'ZZP')"
                  >
                   <i
                    class="fas fa-home"
                   />
                  </button>
                  <button
                   :class="selectedUsageProfile === 7 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = 'Eenmanszaak'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(7, 'Eenmanszaak')"
                  >
                   <i
                    class="fas fa-store-alt"
                   />
                  </button>
                  <button
                   :class="selectedUsageProfile === 8 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = 'Winkel'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(8,'Winkel')"
                  >
                   <i
                    class="fas fa-store"
                   />
                  </button>
                  <button
                   :class="selectedUsageProfile === 9 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = 'Kantoorpand'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(9,'Kantoorpand')"
                  >
                   <i
                    class="fas fa-building"
                   />
                  </button>
                  <button
                   :class="selectedUsageProfile === 10 ? 'selected mr-1 mt-2 mb-2':'mr-1 mt-2 mb-2'"
                   variant="outline-primary"
                   @mouseenter="usageEstimatorHoverText = 'Industrieel'"
                   @mouseleave="usageEstimatorHoverText = ''"
                   @click.prevent="setUsage(10,'Industrieel')"
                  >
                   <i
                    class="fas fa-industry"
                   />
                  </button>
                 </EstimateSolarButtonWrapper>
                </a-col>
                <a-col :xs="24">
                 <div style="text-align: center">
                  <span style="font-size: 10px">{{
                    selectedUsageProfile !== null ? selectedUsageText : usageEstimatorHoverText
                   }}</span>
                 </div>
                </a-col>
               </a-row>
              </a-form-item>
              <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                           label="Stroomverbruik"
                           name="solarEnergyUsage">
               <a-input v-model:value="order.solarEnergyUsage"
                        addon-after="kWh per jaar"
                        name="solarEnergyUsage"
               />
              </a-form-item>
              <a-form-item
               v-if="order.allowDiscount && !canSetDeposit"
               :rules="[{ required: true, validator: validateDiscount,trigger: 'change'}]"
               label="Korting"
               name="discount">
               <a-input v-model:value="order.discount"
                        :addon-after="order.discountType === 'PANEL' ? 'euro per paneel' : '%'"
                        name="discount"
                        type="number"
               />
              </a-form-item>
              <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'}]"
                           label="Korting:"
                           name="discount">
               <a-select v-model:value="order.discount"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in depositOptions"
                                 :key="index"
                                 :value="item.value" name="discount">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
              <!--              <a-form-item-->
              <!--               v-if="canSetDeposit"-->
              <!--               :rules="[{required: true,message: 'Dit veld is verplicht'}]"-->
              <!--               label="Korting"-->
              <!--               name="discount">-->
              <!--               <a-input v-model:value="order.discount"-->
              <!--                        :disabled="userData.role !== 1 && userData.role !== 4"-->
              <!--                        addon-after="%"-->
              <!--                        name="discount"-->
              <!--                        type="number"-->
              <!--                        @input="order.discount = Math.abs(order.discount)"-->
              <!--               />-->
              <!--              </a-form-item>-->
              <a-form-item>
               <a-button :disabled="loadingFetchSolarPackagesData"
                         :loading="loadingFetchSolarPackagesData"
                         class="btn-signin w-100"
                         html-type="submit"
                         size="large"
                         type="primary"> Producten ophalen
               </a-button>
              </a-form-item>
             </a-form>
            </sdCards>
           </a-col>
           <a-col :md="18">
            <sdCards v-if="solarPackages.data.length < 1" headless>
             <a-empty description="Voer het jaarlijkse stroomverbruik van de klant in en klik op producten ophalen."/>
            </sdCards>
            <a-row v-else :key="solarPackages.data.key" :gutter="30">
             <a-col v-for="item in solarPackages.data" :key="item.id" :sm="8"
                    :xs="24" class="mb-30">
              <Suspense>
               <template #default>
                <ProductCardSolarWithPanelCount
                 :data="item" :discount="order.discount"
                 :energyLabel="order.energyLabel"
                 :maxPanels="solarPackages.maxPanels"
                 :minPanels="solarPackages.minPanels"
                 :panelValues="item.panelValues"
                 :roofType="order.roofType"
                 :surfaces="order.surfaces"
                 @handle-product-selection="handleSelectProduct(item)"/>
               </template>
               <template #fallback>
                <sdCards headless>
                 <a-skeleton :paragraph="{ rows: 22 }" active/>
                </sdCards>
               </template>
              </Suspense>
             </a-col>
            </a-row>
           </a-col>
           <ActionWrapper>
            <div class="step-action-wrap">
             <div class="step-action-inner">
              <a-row>
               <a-col :xs="24">
                <div class="steps-action">
                 <sdButton class="btn-prev" type="primary" @click.prevent="productPageBack">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </div>
               </a-col>
              </a-row>
             </div>
            </div>
           </ActionWrapper>
          </a-row>
         </template>
         <template #relation>
          <Relation
           ref="relationComponentRef"
           :disable-is-business="true"
           :enable-birthday="false"
           :is-order-create="true"
           :maxAge="80"
           :minAge="18"
           :relation="order.relation"
           :show-card-save-button="false"
           :show-footer="false"
           :show-global-save-button="true"
           :showHeader="false"
           :showIBAN="false"
           type="ADD"
           @submit-external="handleRelationOnOrder($event)"
          ></Relation>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Vorige</span>
                </sdButton>
                <sdButton class="btn-next" type="primary"
                          @click.prevent="$refs.relationComponentRef.submitExternal()">
                 <span>Volgende</span>
                 <sdFeatherIcons size="16" type="arrow-right"/>
                </sdButton>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #summary>
          <Main>
           <a-row :gutter="25">
            <a-col :md="10" :xs="24">
             <Suspense>
              <template #default>
               <ProductPriceSolarInfoCard :data="order.package" :orderFlow="true" :priceSummary="true"/>
              </template>
             </Suspense>
            </a-col>
            <a-col :md="14" :xs="24">
             <sdCards title="Product">
              <a-row>
               <a-col :md="12" :xs="24">
                <UserBioBox>
                 <div class="user-info" style="padding:0">
                  <ul class="user-info__contact">
                   <li>
                    <i style="color:#000000">Vermogen:</i>
                    <span>{{
                      parseInt(getPanelAttribute(order.package.calculation, 'panel', 'count')) * parseInt(getPanelAttribute(order.package.calculation, 'panel', 'wattPeak'))
                     }} Wp</span>
                   </li>
                   <li>
                    <i style="color:#000000">Terugverdientijd:</i>
                    <span>{{
                      order.package.paybackPeriod.years > 0 ? `${order.package.paybackPeriod.years} jaar` : ''
                     }}{{
                      order.package.paybackPeriod.months > 0 ? ` en ${order.package.paybackPeriod.months} maanden` : ''
                     }}</span>
                   </li>
                  </ul>
                 </div>
                </UserBioBox>
               </a-col>
               <a-col :md="12" :xs="24">
                <UserBioBox>
                 <div class="user-info" style="padding:0">
                  <ul class="user-info__contact">
                   <li>
                    <i style="color:#000000">Huidige energielabel:</i>

                    <span v-if="order.energyLabel">
                     <span :style="`background-color:${getEnergyLabelColor(order.energyLabel)}`"
                           class="energy-label">{{ order.energyLabel }}</span>
                     <span :style="`border-left: 11px solid ${getEnergyLabelColor(order.energyLabel)}`"
                           class="energy-label_arrow"></span>
                    </span>
                    <span v-else> niet beschikbaar</span>
                   </li>
                   <li>
                    <i style="color:#000000">Opbrengst eerste jaar:</i>
                    <span>{{ order.package.wp1Year }} kWh</span>
                   </li>
                  </ul>
                 </div>
                </UserBioBox>
               </a-col>
              </a-row>
             </sdCards>
             <Suspense>
              <template #default>
               <RelationSVMSummaryCard :order="order"/>
              </template>
             </Suspense>
            </a-col>
           </a-row>
           <a-row :gutter="25">
            <a-col :md="8" :xs="24">
             <sdCards
              v-if="order && order.solarSatelliteImage"
              title="Satteliet afbeelding">
              <img :src="order.solarSatelliteImage" alt="" height="300" style="width: 100%">
             </sdCards>
            </a-col>
            <a-col v-if="order.package && order.package.monthChartImageURL" :md="8" :xs="24">
             <sdCards
              :title="`Opbrengst per maand ${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear()}`"
              style="text-align: center">
              <img :src="order.package.monthChartImageURL" alt="" height="300" style="width: 100%">
             </sdCards>
            </a-col>
            <a-col v-if="order.package && order.package.yearChartImageURL" :md="8" :xs="24">
             <sdCards style="text-align: center" title="Opbrengst per jaar">
              <img :src="order.package.yearChartImageURL" alt="" height="300" style="width: 100%">
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.solarAddonProducts.length > 0" :gutter="25">
            <a-col :xs="24">
             <sdCards
              title="Extra services">
              <a-row :gutter="25">
               <a-col v-for="(item,index) in order.solarAddonProducts" :key="index + order.key" :md="12" :xs="24">
                <a-row :gutter="25" style="background-color: #dddddd;border-radius: 5px;margin:10px">
                 <div
                  style="display: contents;">
                  <a-col :xs="14" style="margin: auto">
                   <label>{{ item.name }}</label>
                  </a-col>
                  <a-col :xs="10">
                   <ProductCard style="background-color: transparent !important;">
                    <div class="product-single-action cart-single-quantity"
                         style="justify-content: center;margin:0 !important;">
                     <sdButton :disabled="item.quantity < 1" class="btn-dec"
                               type="default"
                               @click.prevent="setSolarAddonQuantity({index:index,type:'min'})">
                      <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
                     </sdButton>
                     <div style="color: #000000; margin-left: 5px;margin-right: 5px;width:30px">
                      <a-input
                       v-model:value="item.quantity"
                       disabled
                       style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
                       type="input"/>
                     </div>
                     <sdButton class="btn-inc" type="default"
                               @click.prevent="setSolarAddonQuantity({index:index,type:'plus'})">
                      <sdFeatherIcons size="12" type="plus"/>
                     </sdButton>
                    </div>
                   </ProductCard>
                  </a-col>
                 </div>
                </a-row>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
           <a-row :gutter="25">
            <a-col :xs="24">
             <sdCards
              title="Geselecteerde dakdelen">
              <RoofSelectionTable :data="order.surfaces"/>
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.flowQuestions.length > 0" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Product vragen">
              <a-form ref="flowQuestionsForm" :model="{flowQuestions:order.flowQuestions}"
                      layout="vertical">
               <a-row :gutter="25">
                <a-col v-for="(item,index) in order.flowQuestions" :key="index" :md="12" :xs="24">
                 <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                                  :disabled-date="disabledStartDate"
                                  :format="dateFormat" class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()" :format="dateFormat"
                                  class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-input v-model:value="item.value" placeholder=""/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER' && item.type !== 'PANELCOUNT'"
                              :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER' && item.type === 'PANELCOUNT'"
                              :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" :disabled="true" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-select v-model:value="item.value" :allowClear="true" class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(option,index) in item.inputValues"
                                    :key="index"
                                    :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Ondertekenen">
              <a-row :gutter="25">
               <a-col :xs="12" class="signature align-center">
                <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'" :sigOption="sigState.option"
                               :w="'450px'"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                 <div role="alert">Uw handtekening is vereist</div>
                </div>
                <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                 <span>Opnieuw</span>
                </sdButton>
               </a-col>
               <a-col :xs="12" class="mb-5">
                <a-form ref="customerApprovalsForm" :model="order"
                        layout="vertical">
                 <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"
                              :name="['product','customerApprovalsValidation', index]"
                              :rules="{ validator: checkCheckBox }" class="checkbox-group"
                              label=""
                              style="padding-bottom:0px;margin-bottom:0px">
                  <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"
                              :name="['product','customerApprovalsValidation', index]">
                   {{ item }}
                  </a-checkbox>
                 </a-form-item>
                </a-form>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
          </Main>
          <ActionWrapper v-if="!isFinished">
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Terug</span>
                </sdButton>
                <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                          type="primary"
                          @click="handleCreateOrder"> Aanmaken
                </a-button>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateSolarButtonWrapper,
 FigureWizards,
 MixedCardWrap,
 OrderMapInfoCard,
 OrderSummary,
 OrderSummaryInfoCard,
 ProductCard,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {BasicFormWrapper, DatePickerWrapper, Main, ModalMain} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, Transition, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import FlowCards from "./component/FlowCards";
import {
 defaultOptions,
 depositOptions,
 roofTypeOptions,
 solarOrderFlowSteps,
 solarShadowLossOptions,
 usageEstimationOptions,
} from "../../utility/enums";
import {ActionWrapper} from "../../components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "../../utility/validators";
import ConnectionTable from "./component/ConnectionTable";
import RelationSVMSummaryCard from "./component/RelationSVMSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRouter} from "vue-router";
import ProductCardSolarWithPanelCount from "@/view/orders/component/ProductCardSolarWithPanelCount";
import ProductSummaryInfoCard from "@/view/orders/component/ProductSummaryInfoCard";
import ProductPriceSolarInfoCard from "@/view/orders/component/ProductPriceSolarInfoCard";
import {LControl, LControlLayers, LFeatureGroup, LGeoJson, LMap} from "@vue-leaflet/vue-leaflet";
import {OverviewCard} from "@/view/dashboard/style";
import {formatterShadowLossText, getEnergyLabelColor, getRoofPotentialInfo} from "@/utility/utility";
import {UserBioBox} from "@/view/orders/component/style";
import RoofSelectionTable from "@/view/orders/component/RoofSelectionTable";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {
 calculateArea,
 getLayerColor,
 getOrientationOfEdge,
 getPanelAttribute,
 lineAtAngle
} from "@/utility/solarUtility";
import {SimpleMapScreenshoter} from "leaflet-simple-map-screenshoter";

const OrderCreate = {
 name: 'Solar',
 components: {
  ConnectionTable,
  RelationSVMSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ConnectionCardWrapper,
  RelationCard,
  ActionWrapper,
  EstimateSolarButtonWrapper,
  FlowCards,
  DatePickerWrapper,
  ProductCardSolarWithPanelCount,
  ProductSummaryInfoCard,
  ProductPriceSolarInfoCard,
  OrderMapInfoCard,
  LMap,
  LGeoJson,
  LControl,
  LControlLayers,
  LFeatureGroup,
  Transition,
  MixedCardWrap,
  OverviewCard,
  OrderSummaryInfoCard,
  UserBioBox,
  RoofSelectionTable,
  ProductCard,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 watch: {
  addressForm: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.addressForm.postalCode,
       houseNumber: this.addressForm.houseNumber,
       country: this.addressForm.country,
       type: 'SOLAR',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const relationComponentRef = ref();
  const houseNumberAdditionsOptions = computed(() => state.globalSearch.houseNumberAdditions);
  const globalSearchData = computed(() => state.globalSearch.data);
  const globalSearchLoading = computed(() => state.globalSearch.loadingSearch);
  const disabledGlobalSearchButton = computed(() => state.globalSearch.disabledGlobalSearchButton);
  const loadingFetchSolarProducts = ref(computed(() => state.solar.loadingFetchSolarProducts));
  const order = ref(computed(() => state.solar.order));
  const usageEstimatorHoverText = ref('');
  const loadingCreateOrder = computed(() => state.solar.loadingCreateOrder);
  const loadingPreFillRelationOnSolarOrder = computed(() => state.solar.loadingPreFillRelationOnSolarOrder);
  const loadingFetchSolarPackageData = computed(() => state.solar.loadingFetchSolarPackageData);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const loadingFetchSolarPackagesData = computed(() => state.solar.loadingFetchSolarPackagesData);
  const solarPackages = computed(() => state.solar.solarPackages);
  const customerApprovalsForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const flowQuestionsForm = ref();
  const selectedUsageProfile = ref(null);
  const selectedUsageText = ref('');
  const map = ref(null);
  const featureGroup = ref(null);
  const selectedComponent = reactive({selectedComponent: null,});
  const optionsKey = ref(Math.random());
  const mapCenter = ref(null);
  const canSetDeposit = ref(false);
  const userData = JSON.parse(localStorage.getItem('userData'))
  const roofData = computed(() => {
   if (state.solar.roofData) {
    mapCenter.value = JSON.parse(JSON.stringify(state.solar.roofData)).features[0].geometry.coordinates[0][0].reverse()
   }
   return state.solar.roofData;
  });
  const loadingFetchRoofData = computed(() => state.solar.loadingFetchRoofData);

  const rulesAddComponent = {
   selectedComponent: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'SOLAR',
   });

   dispatch('cleanGlobalSearch');
   dispatch('resetSolarOrder');
   selectedUsageProfile.value = null;
   selectedUsageText.value = '';
  };

  const handleSelectRelation = async (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }

   let address = null;
   if (!relation.houseNumberAddition || relation.houseNumberAddition === '') {
    address = {postalCode: relation.postalCode, houseNumber: relation.houseNumber}
   } else {
    address = {
     postalCode: relation.postalCode,
     houseNumber: relation.houseNumber,
     houseNumberAddition: relation.houseNumberAddition
    }
   }

   await dispatch('resetSolarOrder');
   await dispatch('fetchRoofData', address)
   await dispatch('preFillRelationOnSolarOrder', {value: relation, stepRoute: next});
   selectedUsageProfile.value = null;
   selectedUsageText.value = '';
  };

  const handleRelationOnOrder = (data) => {
   dispatch('addRelationToSolarOrder', {value: data, stepRoute: next})
  };

  const handleSelectProduct = (item) => {
   dispatch('setProductOnSolarOrder', {item: item, stepRoute: next});
   dispatch('fetchSolarPackageAddon', item.product.id);
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleCreateOrder = () => {
   const orderValidators = []
   signatureError.value = false;
   if (order.value.flowQuestions && order.value.flowQuestions.length > 0) {
    orderValidators.push(flowQuestionsForm.value.validate());
   }
   if (order.value.signatureType === 'SIGNATURE') {
    orderValidators.push(customerApprovalsForm.value.validate());
   }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureSolarData', signature1.value.save('image/svg+xml'))
      dispatch('createSolarOrder', {successRoute: orderCreateSuccessRoute})
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createSolarOrder', {successRoute: orderCreateSuccessRoute})
    }
   })
    .catch(() => dispatch('orderCreateValidationError'))
  };

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  const options = computed(() => {
   return {
    key: optionsKey.value,
    stroke: true,
    weight: 1,
    onEachFeature: function onEachFeature(feature, layer) {
     // make self created layers deletable
     if (layer.feature.properties.ID.toString().startsWith('100.')) {
      layer.options.pmIgnore = false;
     } else {
      layer.options.pmIgnore = true;
     }
     L.PM.reInitLayer(layer);

     // Set selected layers on load
     if (layer && layer.feature) {
      const index = order.value.selectedRoofLayers.indexOf(layer.feature.properties.ID)
      if (index > -1) {
       layer.setStyle({
        fillColor: '#1fcdff',
        color: '#1fcdff',
       });
      }
     }
     layer.on('click', async function (e) {
      const index = order.value.selectedRoofLayers.indexOf(layer.feature.properties.ID)
      if (index > -1) {
       e.target.setStyle({
        fillColor: getLayerColor(e.target.feature.properties.Roofpotential),
        color: getLayerColor(e.target.feature.properties.Roofpotential),
       });
       order.value.selectedRoofLayers.splice(index, 1);
       await dispatch('removeSelectedRoofLayers', index);
       await dispatch('removeSurfaceFromOrder', e.target.feature);
      } else {
       e.target.feature.properties.RoofType = parseInt(e.target.feature.properties.Inclination) >= 5 ? "SLANTEDROOF" : "FLATROOF";
       e.target.setStyle({
        fillColor: '#1fcdff',
        color: '#1fcdff',
       });
       await dispatch('addSelectedRoofLayers', e.target.feature.properties.ID);
       await dispatch('addSurfaceToOrder', e.target.feature);
       // updateCanvasSurfaceInclination(e.target.feature.properties.Inclination, e.target.feature);
      }
     });
    },
    style: function (feature) {
     return {
      fillColor: getLayerColor(feature.properties.Roofpotential),
      color: getLayerColor(feature.properties.Roofpotential),
     };
    }
   }
  });

  let currentMap = null;
  const onFeatureGroupReady = () => {
   currentMap = map.value.leafletObject._map;
   currentMap.pm.setGlobalOptions({
    allowSelfIntersection: false
   });
   currentMap.pm.setLang('nl');
   currentMap.pm.addControls({
    position: 'topleft',
    drawCircle: false,
    drawMarker: false,
    drawCircleMarker: false,
    drawPolyline: false,
    drawText: false,
    cutPolygon: false,
    dragMode: false,
    rotateMode: false,
    removalMode: true,
    editMode: false,
   });

   currentMap.on('pm:create', async (e) => {
    const {layer, shape} = e;
    if (shape.toLowerCase() === "polygon" || shape.toLowerCase() === "rectangle") {
     const geoLayer = layer.toGeoJSON();
     // const newLayer = L.GeoJSON.geometryToLayer(geoLayer.geometry);
     layer.feature = {};
     layer.feature.properties = {};
     layer.feature.geometry = {};
     layer.feature.properties.ID = 100 + Math.floor(Math.random() * 100);
     layer.feature.properties.Inclination = 0; // gevraagd worden
     layer.feature.properties.Latitude = layer.getBounds().getCenter().lat;
     layer.feature.properties.Longitude = layer.getBounds().getCenter().lng;
     layer.feature.properties.Orientation = 0; // gevraagd worden
     layer.feature.properties.Area = calculateArea(layer.getLatLngs()[0]).toFixed(2);
     layer.feature.properties.SurfaceType = "Flat";
     layer.feature.properties.RoofType = "FLATROOF";
     layer.feature.geometry = geoLayer.geometry;
     layer.feature.properties.Roofpotential = 0;
     layer.feature.properties.BAGID = -1; // VASTE WAARDE
     layer.feature.properties.Gutter = 1;
     layer.feature.properties.MountingPV = null; // VASTE WAARDE
     layer.feature.properties.Performance = 0; // VASTE WAARDE
     layer.feature.properties.Municipality = order.value.relation.city;
     layer.feature.properties.Place = order.value.relation.city;
     layer.feature.properties.Postalcode = order.value.relation.postalCode;
     layer.feature.properties.Purpose = -1; // Vaste waarde
     layer.feature.properties.Roofpotential = 8; // gevraagd worden
     layer.feature.properties.SecondAdditive = '';// VASTE WAARDE
     layer.feature.properties.ShadowLoss = 0;
     layer.feature.properties.Street = order.value.relation.street;
     layer.feature.properties.Housenumber = order.value.relation.houseNumber;
     layer.feature.properties.Additive = order.value.relation.houseNumberAddition ? order.value.relation.houseNumberAddition : '';
     layer.feature.type = 'Feature';
     layer.options.pmIgnore = false;
     layer.setStyle({
      fillColor: '#1fcdff',
      color: '#1fcdff',
     });
     // console.log(layer.feature.properties.ID)
     await dispatch('addSurfaceToOrder', layer.feature);
     await dispatch('addFeatureToRoofData', layer.feature);
     // await updateCanvasSurfaceInclination(0, layer.feature);
     await L.PM.reInitLayer(layer);
     await addEdgesToSelfCreatedLayers(geoLayer, layer.feature.key);
     await dispatch('addSelectedRoofLayers', layer.feature.properties.ID);
    }
   });
   // currentMap.on('pm:edit', (e) => {
   //  const {layer} = e;
   //  console.log('pm:edit', layer)
   // });
   // currentMap.on('pm:update', (e) => {
   //  const {layer} = e;
   //  console.log('pm:update', layer)
   // });
   currentMap.on('pm:remove', (e) => {
    if (e?.layer?.feature?.key) {
     selfCreatedLayers.value.forEach(function (layer) {
      if (layer.key === e.layer.feature.key) {
       currentMap.removeLayer(layer.polyline);
      }
     });

     dispatch('removeSurfaceFromOrderByKey', e.layer.feature.key);
    }
   });
  };

  const selfCreatedLayers = ref([]);
  const addEdgesToSelfCreatedLayers = (geoLayer, key) => {
   L.geoJson(geoLayer, {
    onEachFeature: (feature) => {
     const lonlats = [];
     feature.geometry.coordinates[0].forEach(function (f) {
      lonlats.push([f[1], f[0]]);
     });

     // store line coordinates
     const polylineCoordinates = [];
     for (let h = 0; h < lonlats.length - 1; h++) {
      polylineCoordinates.push([lonlats[h], lonlats[h + 1]]);
     }

     for (let i = 0; i < polylineCoordinates.length; i++) {
      const polyline = L.polyline(polylineCoordinates[i], {
       fillColor: '#1fcdff',
       color: '#1fcdff',
       weight: 4,
      }).addTo(currentMap)
       .on('click', async function () {
        await dispatch('updateSurfaceOrientationOnOrder', {
         Orientation: getOrientationOfEdge(this._latlngs, geoLayer.geometry.coordinates[0]),
         key: key
        });
        this.setStyle({
         color: "#2E2252",
         weight: 4,
        });
        clearRoofOverlays(selfCreatedLayers.value)
       })
       .on('mouseover', function () {
        this.setStyle({
         color: "#2E2252",
         weight: 4,
        });
       })
       .on('mouseout', function () {
        this.setStyle({
         color: '#1fcdff',
         weight: 4,
        });
       });
      selfCreatedLayers.value.push({polyline: polyline, key: key});

      // dispatch('addSurfaceToOrder', polyline);
     }
    }
   })
  };

  const clearRoofOverlays = (overlays) => {
   for (let i = 0; i < overlays.length; i++) {
    map.value.leafletObject._map.removeLayer(overlays[i]);
   }
  }

  const updateInclination = (key, inclination) => {
   dispatch('updateSurfaceOnOrder', {key: key, inclination: inclination});
  }

  const updateCanvasSurfaceInclination = (inclination, feature) => {
   // const canvas = document.getElementById(`canvasSurfaceInclination${feature.key}`);
   const ctx = document.getElementById(`canvasSurfaceInclination${feature.key}`).getContext("2d");
   const startingXPosition = 5;
   const startingYPosition = 30;
   const angle = -inclination;
   const length = 25;

   // ctx.clearRect(0, 0, document.getElementById(`canvasSurfaceInclination${feature.key}`).width, document.getElementById(`canvasSurfaceInclination${feature.key}`).height);
   // ctx.beginPath();
   ctx.lineWidth = 2;
   lineAtAngle(startingXPosition, startingYPosition, length, angle, ctx, false);
   lineAtAngle(startingXPosition, startingYPosition, length, 0, ctx, false);
   // ctx.closePath();
  }

  const adjustInclination = (inclination, feature) => {
   // updateCanvasSurfaceInclination(inclination, feature);
   updateInclination(feature.key, inclination);
  }

  const setUsage = (selection, text) => {
   if (selectedUsageProfile.value === selection) {
    dispatch('setElectricityUsageOnSolarOrder', null);
    selectedUsageProfile.value = null;
    selectedUsageText.value = '';
    return;
   }
   dispatch('setElectricityUsageOnSolarOrder', usageEstimationOptions[selection].electricity);
   selectedUsageProfile.value = selection;
   selectedUsageText.value = text;
  };

  const fetchSolarPackages = () => {
   dispatch('fetchSolarPackages', {
    surfaces: order.value.surfaces,
    totalUsage: order.value.solarEnergyUsage,
    discount: order.value.discount && order.value.discount !== '' ? parseFloat(order.value.discount) : 0,
   });
  }

  const productPageBack = () => {
   optionsKey.value = Math.random();
   dispatch('resetSolarUsageData');
   dispatch('resetSolarProduct');
   prev();
  }

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const validateDiscount = async () => {
   const value = order.value.discount;
   if (value > order.value.maxDiscountPercentage) {
    if (order.value.discountType === 'ORDER') {
     return Promise.reject(`Maximaal ${order.value.maxDiscountPercentage}% korting`);
    } else {
     return Promise.reject(`Maximaal ${order.value.maxDiscountPercentage} euro korting per paneel`);
    }
   } else {
    return Promise.resolve();
   }
  };

  const setSolarAddonQuantity = data => {
   dispatch('setSolarAddonQuantity', data);
  }

  const mapNext = () => {
   let pluginOptions = {
    cropImageByInnerWH: true, // crop blank opacity from image borders
    hidden: true, // hide screen icon
    preventDownload: true, // prevent download on button click
   }
   const simpleMapScreenshoter = new SimpleMapScreenshoter(pluginOptions).addTo(currentMap)

   map.value.leafletObject.eachLayer(function (layer) {
    if (layer?.feature) {
     if (layer.options.color !== '#1fcdff') {
      layer.options.OldColor = layer.options.color;
      layer.options.OldFillColor = layer.options.fillColor;
      layer.setStyle({
       fillColor: '',
       color: '',
      });
     }
    }
   });

   simpleMapScreenshoter.takeScreen('image').then(async base64 => {
    map.value.leafletObject.eachLayer(function (layer) {
     if (layer?.feature) {
      if (layer.options.color !== '#1fcdff') {
       layer.setStyle({
        fillColor: layer.options.OldFillColor,
        color: layer.options.OldColor,
       });
      }
     }
    });
    await dispatch('setSolarSatelliteImage', base64);
    await dispatch('resetSolarUsageData');
    await dispatch('resetSolarProduct');
    next();
   }).catch(() => {
    map.value.leafletObject.eachLayer(function (layer) {
     if (layer?.feature) {
      if (layer.options.color !== '#1fcdff') {
       layer.setStyle({
        fillColor: layer.options.OldFillColor,
        color: layer.options.OldColor,
       });
      }
     }
    });
    dispatch('showErrNotification', 'Er is een fout opgetreden bij het verwerken van de map. probeer het nogmaals, indien het blijft voorkomen neem contact op met uw IT afdeling.');
   })
  }

  const determineSetDeposit = async () => {
   const tenant = localStorage.getItem('tenant');
   switch (String(tenant)) {
    case '11':
    case '16':
    case '21':
    case '17':
     order.value.discount = 10;
     canSetDeposit.value = true;
     break;
    default:
     canSetDeposit.value = false;
     break;
   }
  }

  onMounted(async () => {
   await determineSetDeposit();
   await dispatch('resetSolarOrder');
   await dispatch("cleanGlobalSearch");
   await dispatch('fetchSolarProducts', {panelCount: 0});
  });

  return {
   steps: solarOrderFlowSteps,
   defaultOptions,
   solarShadowLossOptions,
   roofTypeOptions,
   setUsage,
   clearSignature,
   handleSelectRelation,
   globalSearch,
   done,
   prev,
   next,
   handleRelationOnOrder,
   handleCreateOrder,
   onHandleChange,
   onShowSizeChange,
   moment,
   prevEan,
   checkCheckBox,
   handleSelectProduct,
   getRoofPotentialInfo,
   formatterShadowLossText,
   fetchSolarPackages,
   getEnergyLabelColor,
   productPageBack,
   integerValidator,
   onFeatureGroupReady,
   addEdgesToSelfCreatedLayers,
   getPanelAttribute,
   updateInclination,
   signature1,
   addressForm,
   addressConnectionForm,
   houseNumberAdditionsOptions,
   addressFormRules,
   globalSearchData,
   order,
   globalSearchLoading,
   disabledGlobalSearchButton,
   relationComponentRef,
   sigState,
   isFinished,
   current,
   pageSize,
   form,
   loadingPreFillRelationOnSolarOrder,
   customerApprovalsForm,
   signatureError,
   loadingCreateOrder,
   loadingHouseNumberAdditions,
   selectedComponent,
   rulesAddComponent,
   solarPackages,
   loadingFetchSolarProducts,
   options,
   roofData,
   mapCenter,
   usageEstimatorHoverText,
   loadingFetchRoofData,
   selectedUsageProfile,
   selectedUsageText,
   optionsKey,
   loadingFetchSolarPackageData,
   loadingFetchSolarPackagesData,
   flowQuestionsForm,
   map,
   featureGroup,
   adjustInclination,
   validateDiscount,
   setSolarAddonQuantity,
   mapNext,
   updateCanvasSurfaceInclination,
   depositOptions,
   canSetDeposit,
   userData
  };
 },
};

export default OrderCreate;
</script>
<style lang="scss">
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}

.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
 opacity: 0;
}

.removeMinHeight {
 .ant-form-item-control {
  .ant-form-item-control-input {
   min-height: auto !important;
  }
 }
}

.project-title {
 display: flex;
 align-items: flex-start;
 -webkit-box-pack: justify;
 justify-content: space-between;
}

.ant-slider-mark {
 font-size: 11px !important;
}

.ant-slider-dot {
 border: 2px solid #ccc !important;
}

.ant-slider-step {
 background-color: #ccc !important;
}

.ant-popover-content {
 max-width: 300px !important;
}

.energy-label {
 vertical-align: middle;
 padding-left: 4px;
 padding-top: 0px;
 text-align: left;
 line-height: 1.8;
 color: white;
 display: inline-block;
 height: 25px;
 width: 50px;
 font-size: 14px;
}

.energy-label_arrow {
 vertical-align: middle;
 display: inline-block;
 width: 0;
 height: 0;
 border-top: 13px solid transparent;
 border-bottom: 13px solid transparent;
}

.mapSelect {
 height: 50px !important;
 line-height: 48px !important;

 .ant-select-selector {
  padding: 0 20px !important;
  height: 48px !important;
  border: 1px solid #E3E6EF !important;
 }
}

.btn-inc:hover i, .btn-inc:hover svg, .btn-dec:hover i, .btn-dec:hover svg {
 color: #ffffff;
}

.cart-single-quantity button {
 padding: 0 !important;
 background-color: rgb(244, 245, 247);

 :hover {
  color: #ffffff !important
 }
}

.uspText {
 color: #000000;

 ul {
  list-style: disc !important;
  padding: 0 20px !important;

  li {
   text-align: start !important;
  }
 }
}
</style>


<template>
  <sdUserCard>
      <sdCards title="Product">
        <div class="card productRevision-card">
        <a-skeleton v-if="!data" active/>
        <template v-else>
          <figure>
            <img :src="`${data.imageFileURL}`" alt="" style="max-width: 200px"/>
          </figure>
          <figcaption>
            <div class="card__content">
              <sdHeading class="card__name" as="h6">
                {{ data.name }}
              </sdHeading>
            </div>
          </figcaption>
        </template>
        </div>
      </sdCards>
  </sdUserCard>
</template>
<script>
import {defineComponent} from 'vue';
import VueTypes from "vue-types";

const ProductSummaryInfoCard = defineComponent({
  name: 'ProductSummaryInfoCard',
  props: {
    data: VueTypes.object.def(null),
  },
});

export default ProductSummaryInfoCard;
</script>

<template>
    <StepsStyle v-if="steps !== undefined" :current="current" :direction="direction" :status="status" :size="size">
      <a-step
        v-for="item in steps"
        :class="item.class && item.class"
        :icon="item.icon && item.icon"
        :key="item.title"
        :title="item.title"
      >
        <template #description>
          <div v-if="item.path" class="wizard-item">
            <h2>{{ item.subTitle }}</h2>
          </div>
        </template>
      </a-step>
    </StepsStyle>
      <div
        class="steps-content"
        :style="{ minHeight: height, display: 'flex', justifyContent: 'center', marginTop: 100 }"
      >
        {{current}}
        <slot :name="steps[current].content"></slot>
      </div>
<!--      <ActionWrapper v-if="!isFinished">-->
<!--        <div class="step-action-wrap">-->
<!--          <div class="step-action-inner">-->
<!--            <a-row>-->
<!--              <a-col :xs="24">-->
<!--                <div class="steps-action">-->
<!--                  <sdButton v-if="currents > 0" class="btn-prev" type="light" @click="() => prev()">-->
<!--                    <sdFeatherIcons type="arrow-left" size="16" />-->
<!--                    <span>Previous</span>-->
<!--                  </sdButton>-->
<!--                  <sdButton v-if="currents < steps.length - 1" class="btn-next" type="primary" @click="() => next()">-->
<!--                    <span>Save & Next</span>-->
<!--                    <sdFeatherIcons type="arrow-right" size="16" />-->
<!--                  </sdButton>-->
<!--                  <sdButton v-if="currents === steps.length - 1" type="primary" @click="onDone">-->
<!--                    <span>Done</span>-->
<!--                  </sdButton>-->
<!--                </div>-->
<!--              </a-col>-->
<!--            </a-row>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ActionWrapper>-->
    </template>
<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';
import { StepsStyle } from '../Style';

const stepStyle = {
  marginBottom: 60,
  boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};

const Steps = defineComponent({
  name: 'Steps',
  components: { StepsStyle },
  props: {
    size: VueTypes.string.def('default'),
    current: VueTypes.number.def(0),
    direction: VueTypes.string.def('horizontal'),
    status: VueTypes.string,
    description: VueTypes.string,
    steps: VueTypes.arrayOf(VueTypes.object),
    wizardItem: VueTypes.arrayOf(VueTypes.object),
    navigation: VueTypes.bool.def(false),
    isFinished: VueTypes.bool.def(false),
    height: VueTypes.number.def(150),
  },
  setup() {
    return {
      stepStyle,
    };
  },
});

export default Steps;
</script>
